// React component with a form to create a new member of the health team
import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby-link";

// typings

// components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import Flex from "../../components/Containers/Flex";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import Wrapper from "../../components/Containers/Wrapper";
import SButton from "../../components/Buttons/SButton";
import Error from "../../components/Errors/Error";
import { Select } from "@material-ui/core";
import { receiverAPI } from "../../utils/api/v2";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;

  ${(props: { margin?: string }) => {
    if (props.margin) {
      return `margin: ${props.margin};`;
    }
  }}

  .MuiFormControl-root {
    margin: 5px 10px;
  }
`;

type Receiver = {
  active: boolean;
  country: "cl" | "mx";
  name: string;
  lat: number;
  lng: number;
  timezone: "America/Santiago" | "America/Mexico_City";
  origin: "lab" | "logistic";
};

const NewReceiver = (): JSX.Element => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [receiverData, setReceiverData] = useState<Receiver>({
    active: true,
    country: "cl",
    name: "",
    lat: 0,
    lng: 0,
    timezone: "America/Santiago",
    origin: "lab",
  });

  const onSubmit = async () => {
    setLoading(true);
    const errors = checkErrors();
    if (errors) {
      setLoading(false);
      return;
    }

    try {
      const req = await receiverAPI.create(receiverData);
      Swal.fire({
        icon: req.status === 201 ? "success" : "error",
        title:
          req.status === 201
            ? "Receptor creado con éxito"
            : "Error al crear receptor",
        text:
          req.status === 201
            ? `Receptor ${receiverData.name} creado`
            : "Error al crear el receptor",
        showConfirmButton: true,
        didClose: () => {
          navigate(-1);
        },
      });
    } catch (err) {
      console.error(err);
      setErrors([err.message]);
    }

    setLoading(false);
  };

  const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const attribute = event.target.id;
    setReceiverData((prevState: Receiver): Receiver => {
      const newState = { ...prevState };
      newState[attribute] = newValue;
      return newState;
    });
  };

  const checkErrors = () => {
    const newErrors: string[] = [];

    // check for errors in the form
    if (!receiverData.name.length) {
      newErrors.push("El nombre es obligatorio");
    }

    setErrors(newErrors);
    return newErrors.length ? true : false;
  };

  return (
    <PrivateRoute>
      <Flex justify="center">
        <Wrapper variant="outlined">
          <h2>Crear nuevo receptor</h2>

          <Error>
            <ul>
              {errors.map((i, key) => (
                <li key={key}>{i}</li>
              ))}
            </ul>
          </Error>

          <Row>
            <TextField
              id="name"
              onChange={formChange}
              value={receiverData.name}
              helperText="Nombre del receptor"
              fullWidth
            />
            <Select
              native
              value={receiverData.country}
              onChange={formChange}
              inputProps={{
                name: "country",
                id: "country",
              }}
            >
              <option value="cl">Chile</option>
              <option value="mx">México</option>
            </Select>
          </Row>
          <Row>
            <Select
              native
              value={receiverData.timezone}
              onChange={formChange}
              inputProps={{
                name: "timezone",
                id: "timezone",
              }}
            >
              <option value="America/Santiago">Timezone de Chile</option>
              <option value="America/Mexico_City">Timezone de México</option>
            </Select>
            <Select
              native
              value={receiverData.origin}
              onChange={formChange}
              inputProps={{
                name: "origin",
                id: "origin",
              }}
            >
              <option value="lab">Laboratorio</option>
              <option value="logistic">Logística</option>
            </Select>
          </Row>
          <Flex align="center" justify="center" margin="2rem 0rem 1rem 0rem">
            <SButton
              variant="outlined"
              color="secondary"
              disabled={loading}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </SButton>
            <SButton
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={onSubmit}
            >
              Crear
            </SButton>
          </Flex>
        </Wrapper>
      </Flex>
    </PrivateRoute>
  );
};

export default NewReceiver;
